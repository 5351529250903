import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { GET_RESTAURANT } from '~graphql/queries';
import LoadingView from '../../components/LoadingView';
import AppContext from '../../contexts/AppContext';
import isBrowser from '../../utils/isBrowser';
import { redirectMessenger } from '../../utils/routeHelper';

const Redirect = () => {
  const { restaurantId, tableId } = useContext(AppContext);

  useQuery(GET_RESTAURANT, {
    variables: {
      restaurantId,
    },
    onCompleted: data =>
      isBrowser() &&
      redirectMessenger(
        tableId
          ? `${data?.restaurant?.messengerUrl}?ref=${tableId}`
          : data?.restaurant?.messengerUrl
      ),
  });

  return <LoadingView />;
};

export default Redirect;
